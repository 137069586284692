import Index from "./view/index/Index";
import Game from "./view/game/Index";
import IndexVideo from "./view/index/Video";
import Task from "./view/task/Index";
import Service from "./view/Service";
import User from "./view/user/Index";
import TodayStartGame from "./view/game/TodayStart";
import ReserveGame from "./view/game/Reserve";
import GameDetail from "./view/game/Detail";
import GameArticle from "./view/game/Article";
import GameServer from "./view/game/detail/Server";
import Article from "./view/article/Index";
import GameVip from "./view/game/Vip";
import GameProject from "./view/game/Project";
import Voucher from "./view/voucher/Index";
import VoucherLateplay from "./view/voucher/Lateplay";
import Server from "./view/server/Index";
import Topic from "./view/topic/Index";
import Credit from "./view/credit/Index";
import CreditGift from "./view/credit/Gift";
import Search from "./view/search/Index";
import Faq from "./view/Faq";
import Trade from "./view/trade/Index";
import TradeDetail from "./view/trade/Detail";
import TradeNotice from "./view/trade/Notice";
import TradeSubmit from "./view/trade/Submit";
import TradeRecord from "./view/trade/Record";
import TradeGood from "./view/trade/Good";
import TradeIndex from "./view/trade/Trade";
import ArticleDetail from "./view/article/Detail";
import CommentDetail from "./view/comment/Detail";
import CommentSubmit from "./view/comment/Submit";
import QAQuestion from "./view/qa/Question";
import QAAnswer from "./view/qa/Answer";
import Img from "./view/general/Img";
import Iframe from "./view/general/Iframe";
import GameSelect from "./view/game/Select";
import Feedback from "./view/feedback/Index";
import Forget from "./view/account/Forget";
import Register from "./view/account/Register";
import Login from "./view/account/Login";
import PhoneLogin from "./view/account/PhoneLogin";
import VerifiCode from "./view/account/VerifiCode";
import Phone from "./view/account/Phone";
import Country from "./view/account/Country";
import Video from "./view/general/Video";
import TaskSign from "./view/task/Sign";
import TaskSignData from "./view/task/SignData";
import TaskNovice from "./view/task/Novice";
import TaskNovices from "./view/task/Novices";
import TaskWelfare from "./view/task/Welfare";
import TaskDay from "./view/task/Day";
import TaskSuccess from "./view/task/Success";
import TaskTryplay from "./view/task/Tryplay";
import TaskTryplayExplain from "./view/task/TryplayExplain";
import Message from "./view/message/Index";
import MessageDetail from "./view/message/Detail";
import MessageComment from "./view/message/Comment";
import MessageQA from "./view/message/QA";
import MessageTopic from "./view/message/Topic";
import Rebate from "./view/rebate/Index";
import RebateGuide from "./view/rebate/Guide";
import RebateSubmit from "./view/rebate/Submit";
import RebateSuccess from "./view/rebate/Success";
import RebateDetail from "./view/rebate/Detail";
import RebateCodes from "./view/rebate/Codes";
import UserProfile from "./view/user/Profile";
import UserNickname from "./view/user/Nickname";
import UserQQ from "./view/user/QQ";
import UserPhone from "./view/user/Phone";
import UserPhoneRemove from "./view/user/PhoneRemove";
import UserPhoneVerify from "./view/user/PhoneVerify";
import UserPhoneReplace from "./view/user/PhoneReplace";
import UserRealName from "./view/user/Realname";
import UserPassword from "./view/user/Password";
import UserCredit from "./view/user/Credit";
import UserVoucher from "./view/user/Voucher";
import UserGame from "./view/user/game/Index";
import UserReserveGame from "./view/user/game/ReserveGame";
import UserLikeGame from "./view/user/game/LikeGame";
import UserGift from "./view/user/Gift";
import UserRecord from "./view/user/record/Index";
import UserSet from "./view/user/Set";
import Transfer from "./view/transfer/Index";
import Invite from "./view/invite/Index";
import InviteRecord from "./view/invite/Record";
import NewInvite from "./view/invite/NewIndex";
import NewInviteRecord from "./view/invite/NewRecord";
import Recovery from "./view/recovery/Index";
import RecoveryDetail from "./view/recovery/Detail";
import RecoveryRecord from "./view/recovery/Record";
import UserBill from "./view/user/Bill";
import Ptb from "./view/ptb/Index";
import PtbRecord from "./view/ptb/Record";
import Save from "./view/save/Index";
import CardRecord from "./view/save/CardRecord";
import Test from "./view/Test";
import Welfare from "./view/welfare/Index";
import Shop from "./view/welfare/Shop";
// import UserSetPasswd from "./view/user/SetPasswd";
import SetPassrd from "./view/user/SetPassrd";
import MessageNew from "./view/message/New";
import Great from "./view/index/Great";
import Couple from "./view/party/couple/Index";
import Regression from "./view/party/Regression";
import Beta from "./view/party/Beta";
import Reserved from "./view/index/new/Reserved";
import CancelAccount from "./view/user/CancelAccount";
import AssetAccount from "./view/user/AssetAccount";
import DetailBag from "./view/game/detail/Bag";
import DetailCoupon from "./view/game/detail/Coupon";
import Infobox from "./view/message/Infobox";
import NewCard from "./view/save/NewCard";
import NewVip from "./view/save/NewVip";
import SaveDescript from "./view/save/Descript";
import AuthorityGame from "./view/user/game/AuthorityGame";
import Complaintdetail from "./view/game/detail/Complaintdetail";
import Report from "./view/game/detail/Report";
import Around from "./view/around/Index";
import AroundDetail from "./view/around/Detail";
import AroundRule from "./view/around/Rule";
import AroundRecord from "./view/around/Record";
import Reserve from "./view/game/Reserve";
import Rank from "./view/index/Rank";
import NewRanks from "./view/index/rank/NewRanks";
import CommunityDetail from "./view/game/detail/Community/detail/CommunityDetail";
import Publish from "./view/game/detail/Community/publish/Publish";
import IMChat from "./view/game/im/chat/IMChat";
import Announcement from "./view/game/im/announcement/Announcement";
import TeamSetting from "./view/game/im/teamSetting/TeamSetting";
import TeamMembers from "./view/game/im/members/TeamMembers";
import Conversation from "./view/game/im/clusters/Conversation";
import Clusters from "./view/game/im/clusters/Clusters";
import trade from "./view/index/trade/trade";
import ClundHangUp from "./view/game/hangup/ClundHangUp";
import HangupQuestion from "./view/game/hangup/question/HangupQuestion";
import HangupFeedback from "./view/game/hangup/feedback/HangupFeedback";
import Iframenogen from "./lib/Iframenogen";
import DetailTrade from './view/game/detail/Trade';
import Worryfree from "./view/game/worryfree/Worryfree";
import ExtendGame from "./view/game/extendgame/ExtendGame";
import Records from "./view/task/records/Records";
import Winning from "./view/task/records/Winning";
import Playing from "./view/game/type/Playing";
import GameSign from "./view/game/gamesign/GameSign";
import SignGift from "./view/game/signgift/SignGift";
import GiftProject from "./view/game/giftproject/GiftProject";
import Overseas from "./Overseas";

export const screenRouter = [
  {
    id: 'index',
    path: '/',
    component: Index,
    title: '首页',
  },
  {
    id: 'game',
    path: '/screen-game',
    component: Game,
    title: '发现',
  },
  {
    id: 'welfare',
    path: '/screen-welfare',
    component: Welfare,
    title: '福利中心',
  },
  // {
  //   id: 'task',
  //   path: '/screen-task',
  //   component: Task,
  //   title: '赚金',
  //   user: true,
  // },
  {
    id: 'trade',
    path: '/screen-trade',
    component: TradeIndex,
    title: '交易',
  },
  // {
  //   id: 'service',
  //   path: '/screen-service',
  //   component: Service,
  //   title: '客服',
  // },
  {
    id: 'user',
    path: '/screen-user',
    component: User,
    title: '我的',
  },
];

export const overseasRouter = [
  {
    id: 'index',
    path: '/',
    component: Index,
    title: '首页',
  },
  {
    id: 'game',
    path: '/screen-game',
    component: Game,
    title: '发现',
  },
  {
    id: 'welfare',
    path: '/screen-welfare',
    component: Welfare,
    title: '福利中心',
  },
  {
    id: 'ptb',
    path: '/screen-trade',
    component: Overseas,
    title: '储值',
    user: true,
  },
  {
    id: 'user',
    path: '/screen-user',
    component: User,
    title: '我的',
  },
];

export const navigationRouter = [
  {
    path: '/overseas-trade',
    component: TradeIndex,
    title: '账号交易',
  },
  {
    path: '/records',
    component: Records,
    title: '领取记录',
  },
  {
    path: '/gamesign',
    component: GameSign,
    title: '',
    invasion: true,
    theme: 'bright',
    color: '255,255,255',
    activeColor: '#282828',
  },
  {
    path: '/signgift',
    component: SignGift,
    title: '',
    invasion: true,
    theme: 'bright',
    color: '255,255,255',
    activeColor: '#282828',
  },
  {
    path: '/giftproject',
    component: GiftProject,
    title: '',
    invasion: true,
    theme: 'bright',
    color: '255,255,255',
    activeColor: '#282828',
  },
  {
    path: '/playing',
    component: Playing,
    title: '正在玩',
  },
  {
    path: '/winning',
    component: Winning,
    title: '领取记录',
  },
  {
    path: '/extendgame',
    component: ExtendGame,
    title: '',
  },
  {
    path: '/worryfree',
    component: Worryfree,
    title: '省心玩',
  },
  {
    path: '/detailTrade',
    component: DetailTrade,
    title: '交易',
  },
  {
    path: '/hangup',
    component: ClundHangUp,
    title: '云挂机',
  },
  {
    path: '/hangupquestion',
    component: HangupQuestion,
    title: '云挂机FAQ',
  },
  {
    path: '/hangupfeedback',
    component: HangupFeedback,
    title: '产品反馈',
  },
  {
    path: '/iframenogen',
    component: Iframenogen,
    title: '产品反馈',
  },
  {
    path: '/chat',
    component: IMChat,
    title: '',
  },
  {
    path: '/announcement',
    component: Announcement,
    title: '群公告',
  },
  {
    path: '/teamSetting',
    component: TeamSetting,
    title: '群聊设置',
  },
  {
    path: '/teamMembers',
    component: TeamMembers,
    title: '群成员',
  },
  {
    path: '/clusters',
    component: Clusters,
    title: '我的群聊',
  },
  {
    path: '/index/trade',
    component: trade,
    title: '',
  },
  {
    path: '/game/today-start',
    component: TodayStartGame,
    title: '新游预约',
  },
  {
    path: '/communityDetail',
    component: CommunityDetail,
    title: '',
  },
  {
    path: '/publish',
    component: Publish,
    title: '',
  },
  {
    path: '/game/reserve',
    component: ReserveGame,
    title: '新游预约',
  },
  {
    path: '/reserve',
    component: Reserve,
    title: '新游预约',
  },
  {
    path: '/newrank',
    component: NewRanks,
    title: '近期好游',
  },
  {
    path: '/game',
    component: GameDetail,
    // title: '游戏详情',
    invasion: true,
    theme: 'gamedetail'
  },
  {
    path: '/game/server',
    component: GameServer,
    title: '开服',
  },
  
  {
    path: '/game/article',
    component: GameArticle,
    title: '活动攻略',
  },
  {
    path: '/article',
    component: Article,
    title: '活动中心',
  },
  {
    path: '/game/vip',
    component: GameVip,
    title: '游戏VIP表',
  },
  {
    path: '/game/project',
    component: GameProject,
  },
  {
    path: '/trade',
    component: Trade,
    title: '账号交易',
  },
  {
    path: '/trade/detail',
    component: TradeDetail,
    title: '商品详情',
  },
  {
    path: '/trade/notice',
    component: TradeNotice,
    title: '交易须知',
    invasion: true,
    theme: 'bright',
    color: '255,190,0',
  },
  {
    path: '/trade/submit',
    component: TradeSubmit,
    title: '我要卖号',
  },
  {
    path: '/trade/record',
    component: TradeRecord,
    title: '交易记录',
  },
  {
    path: '/trade/good',
    component: TradeGood,
    // title: '官方精选',
    title: '超值捡漏',
  },
  {
    path: '/article/detail',
    component: ArticleDetail,
    title: '活动详情',
  },
  {
    path: '/comment',
    component: CommentDetail,
    title: '评论详情',
  },
  {
    path: '/comment/submit',
    component: CommentSubmit,
    title: '发布评论',
  },
  {
    path: '/question',
    component: QAQuestion,
    title: '问答',
  },
  {
    path: '/answer',
    component: QAAnswer,
    title: '问答详情',
  },
  {
    path: '/search',
    component: Search,
    title: '搜索',
  },
  {
    path: '/faq',
    component: Faq,
    title: '常见问题',
  },
  {
    path: '/img',
    component: Img,
    invasion: true,
    theme: 'bright',
  },
  {
    path: '/iframe',
    component: Iframe,
  },
  {
    path: '/video',
    component: Video,
    invasion: true,
  },
  {
    path: '/game/select',
    component: GameSelect,
    title: '选择游戏',
  },
  {
    path: '/feedback',
    component: Feedback,
    title: '投诉反馈',
  },
  {
    path: '/login',
    component: Login,
    title: '账号密码登录',
    invasion: true,
    // theme: 'darklogin',
    theme: 'darkreagein',
  },
  {
    path: '/phonelogin',
    component: PhoneLogin,
    title: '一键登录',
    invasion: true,
    theme: 'dark',
  },
  {
    path: '/register',
    component: Register,
    title: '账号注册',
    invasion: true,
    // theme: 'darklogin',
    theme: 'darkreagein',
  },
  {
    path: '/verificode',
    component: VerifiCode,
    title: '登录',
    invasion: true,
    // theme: 'darklogin',
    theme: 'darkreagein',
    color: '255,190,0',
    // background: '#fff',
  },
  {
    path: '/phone',
    component: Phone,
    title: '绑定手机',
  },
  {
    path: '/phone',
    component: Phone,
    title: '绑定手机',
  },
  {
    path: '/forget',
    component: Forget,
    title: '忘记密码',
  },
  {
    path: '/account/country',
    component: Country,
    title: '选择国家或地区',
  },
  {
    path: '/task/sign',
    component: TaskSignData,
    title: '每日签到',
    invasion: true,
    color: '255,152,0',
  },
  {
    path: '/task/signdata',
    component: TaskSignData,
    title: '每日签到',
    invasion: true,
    theme: 'darklogin',
    color: '255,255,255',
  },
  {
    path: '/task/novice',
    component: TaskNovice,
    title: '新手任务',
    invasion: true,
    color: '255,152,0',
  },
  {
    path: '/task/novices',
    component: TaskNovices,
    title: '新手任务',
    // invasion: true,
    // color: '255,152,0',
  },
  {
    path: '/task/welfare',
    component: TaskWelfare,
    title: '福利任务',
    // invasion: true,
    // color: '255,152,0',
  },
  
  {
    path: '/task/day',
    component: TaskDay,
    title: '每日任务',
    invasion: true,
    color: '255,152,0',
  },
  {
    path: '/task/success',
    component: TaskSuccess,
    title: '成就任务',
    invasion: true,
    color: '255,152,0',
  },
  {
    path: '/task/tryplay',
    component: TaskTryplay,
    title: '试玩任务',
    // invasion: true,
    // color: 'transparent',
    // color: '255,152,0',
  },
  {
    path: '/task/tryplay-explain',
    component: TaskTryplayExplain,
    title: '下载试玩赚金币答疑',
  },
  {
    path: '/voucher',
    component: Voucher,
    title: '领券中心',
    background: '#f8f8f8',
  },
  {
    path: '/voucher/lateplay',
    component: VoucherLateplay,
    title: '大家都在玩',
    background: '#f8f8f8',
  },
  {
    path: '/server',
    component: Server,
    title: '开服表',
  },
  {
    path: '/topic',
    component: Topic,
    title: '游戏圈子',
  },
  {
    path: '/credit',
    component: Credit,
    title: '积分商城',
    invasion: true,
    theme: 'bright',
    color: '255,190,0',
    background: '#f8f8f8',
  },
  {
    path: '/credit/gift',
    component: CreditGift,
    title: '礼包&礼品',
  },
  {
    path: '/message',
    component: Message,
    title: '我的消息',
  },
  {
    path: '/message/detail',
    component: MessageDetail,
    title: '消息详情',
  },
  {
    path: '/message/comment',
    component: MessageComment,
    title: '点评消息',
  },
  {
    path: '/message/qa',
    component: MessageQA,
    title: '问答消息',
  },
  {
    path: '/message/topic',
    component: MessageTopic,
    title: '话题消息',
  },
  {
    path: '/user',
    component: UserProfile,
    title: '个人中心',
  },
  {
    path: '/user/nickname',
    component: UserNickname,
    title: '昵称',
  },
  {
    path: '/user/qq',
    component: UserQQ,
    title: 'QQ',
  },
  {
    path: '/user/phone',
    component: UserPhone,
    title: '绑定手机号',
  },
  {
    path: '/user/phone-remove',
    component: UserPhoneRemove,
    title: '解绑手机号',
  },
  {
    path: '/user/phone-verify',
    component: UserPhoneVerify,
    title: '验证原手机号',
  },
  {
    path: '/user/setpassword',
    component: SetPassrd,
    title: '设置密码',
  },
  {
    path: '/user/phone-replace',
    component: UserPhoneReplace,
    title: '绑定新手机号',
  },
  {
    path: '/user/realname',
    component: UserRealName,
    title: '实名认证',
  },
  {
    path: '/user/password',
    component: UserPassword,
    title: '登录密码',
  },
  {
    path: '/rebate',
    component: Rebate,
    title: '返利中心',
  },
  {
    path: '/rebate/guide',
    component: RebateGuide,
    title: '返利指南',
  },
  {
    path: '/rebate/submit',
    component: RebateSubmit,
    title: '申请详情',
  },
  {
    path: '/rebate/success',
    component: RebateSuccess,
    title: '提交成功',
    invasion: true,
    theme: 'bright',
    color: '44,49,69',
  },
  {
    path: '/rebate/detail',
    component: RebateDetail,
    title: '申请详情',
    // invasion: true,
    // color: '255,152,0',
  },
  {
    path: '/rebate/codes',
    component: RebateCodes,
    title: '礼包码',
  },
  {
    path: '/user/credit',
    component: UserCredit,
    title: '积分记录',
  },
  {
    path: '/user/voucher',
    component: UserVoucher,
    title: '代金券',
  },
  {
    path: '/user/game',
    component: UserGame,
    title: '我的游戏',
  },
  {
    path: '/user/reserve',
    component: UserReserveGame,
    title: '我的预约',
  },
  {
    path: '/user/likeGame',
    component: UserLikeGame,
    title: '足迹',
  },
  {
    path: '/user/gift',
    component: UserGift,
    title: '我的礼包',
  },

  {
    path: '/set',
    component: UserSet,
    title: '设置',
  },
  
  {
    path: '/user/record',
    component: UserRecord,
    title: '我的足迹',
    // invasion: true,
    theme: 'bright',
    color: 'transparent',
    background: '#f8f8f8',
  },
  {
    path: '/transfer',
    component: Transfer,
    title: '充值转游',
  },
  {
    path: '/around',
    component: Around,
    title: '转游福利',
  },
  {
    path: '/around/detail',
    component: AroundDetail,
    title: '转游福利',
  },
  {
    path: '/around/rule',
    component: AroundRule,
    title: '转游说明',
  },
  {
    path: '/around/record',
    component: AroundRecord,
    title: '转游记录',
  },
  {
    path: '/invite',
    component: Invite,
    title: '邀请好友',
    invasion: true,
    theme: 'bright',
    color: '255,190,0',
  },
  {
    path: '/invite/record',
    component: InviteRecord,
    title: '邀请明细',
  },
  {
    path: '/newinvite',
    component: NewInvite,
    title: '邀请好友',
  },
  {
    path: '/invite/newrecord',
    component: NewInviteRecord,
    title: '提现明细',
  },
  {
    path: '/recovery',
    component: Recovery,
    title: '小号回收',
  },
  {
    path: '/recovery/select',
    component: RecoveryDetail,
    title: '选择小号',
  },
  {
    path: '/recovery/record',
    component: RecoveryRecord,
    title: '回收记录',
  },
  {
    path: '/user/bill',
    component: UserBill,
    title: '我的账单',
  },
  {
    path: '/service',
    component: Service,
    title: '客服中心',
    invasion: true,
    theme: 'darklogin',
    // color: '255,190,0',
  },
  {
    path: '/ptb',
    component: Ptb,
    title: '我的钱包',
  },
  {
    path: '/ptb/record',
    component: PtbRecord,
    title: '充值记录',
  },
  {
    path: '/save',
    component: Save,
    title: '会员中心',
    invasion: true,
    theme: 'bright',
    // color: '255,190,0',
    color: '44,49,69',
  },
  {
    path: '/save/newcard',
    component: NewCard,
    title: '省钱卡',
    // invasion: true,
    // theme: 'bright',
    // color: '255,190,0',
    // color: '44,49,69',
  },
  {
    path: '/save/newvip',
    component: NewVip,
    title: '会员中心',
    invasion: true,
    theme: 'bright',
    color: '44,49,69',
  },
  {
    path: '/save/descript',
    component: SaveDescript,
    title: '会员说明',
  },
  {
    path: '/user/authority',
    component: AuthorityGame,
    title: '玩过的云游戏',
  },
  {
    path: '/card/record',
    component: CardRecord,
    title: '购买记录',
  },
  {
    path: '/shop',
    component: Shop,
    title: '金币商城',
    invasion: true,
    theme: 'darklogin',
    // theme: 'bright',
    color: '255,190,0',
    background: '#f8f8f8',
  },
  {
    path: '/index/video',
    component: IndexVideo,
    title: '视频',
    // invasion: true,
    // background: '#000',
  },
  {
    path: '/index/great',
    component: Great,
    title: '视频',
    // invasion: true,
    // background: '#000',
  },
  {
    path: '/party/couple',
    component: Couple,
    title: '新人福利',
  },
  {
    path: '/party/regression',
    component: Regression,
    title: '老用户回归',
  },
  {
    path: '/party/beta',
    component: Beta,
    title: '游戏内测',
  },
  {
    path: '/message/new',
    component: MessageNew,
  },
  {
    path: '/message/infobox',
    component: Infobox,
    title: '平台消息',
  },
  {
    path: '/new/reserved',
    component: Reserved,
    title: '我的预约游戏',
    // invasion: true,
  },
  {
    path: '/detail/complaint',
    component: Complaintdetail,
    title: '游戏折扣举报',
    // invasion: true,
  },
  {
    path: '/detail/report',
    component: Report,
    title: '我的举报',
    // invasion: true,
  },
  {
    path: '/detail/bag',
    component: DetailBag,
    title: '游戏礼包',
  },
  {
    path: '/detail/coupon',
    component: DetailCoupon,
    title: '代金券',
  },
  {
    path: '/user/cancelaccount',
    component: CancelAccount,
    title: '注销账号',
  },
  {
    path: '/user/assetaccount',
    component: AssetAccount,
    title: '账号资产确认',
  },
  {
    path: '/test',
    component: Test,
    title: 'test',
  },
];